<template>
   <div class="col-12" v-if="view == 'Downloads-ManualMarca' || view == 'Downloads-Logos' || view == 'Downloads-Banners' || view == 'Downloads-Paineis'">
      <div class="card">
         <div class="card-body">
            <div class="row text-center align-items-center font-14">
               <div class="wpx-100 w-img">
                  <img :src="arquivo.banner == null ? '' : arquivo.banner" alt="produto" @error="imageError">
               </div>
               <div class="col-5 text-start"><span class="limitador">{{ arquivo.titulo }}</span></div>
               <div class="col d-none d-sm-block"><span class="limitador">{{ arquivo.tipo == null ? '-' : arquivo.tipo }}</span></div>
               <div class="col d-none d-md-block"><span class="limitador">{{ arquivo.tamanho == null ? '-' : arquivo.tamanho }}</span></div>
               <div class="col d-none d-xl-block"><span class="limitador">{{ arquivo.dimensoes == null ? '-' : arquivo.dimensoes }}</span></div>
               <div class="col">
                  <a href="javascript:;" class="limitador" @click="download" v-if="arquivo.urlDownload != null">Download</a>
                  <span class="limitador" v-else>-</span>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div v-else class="product__item white-bg cursor-pointer p-12" @click="visualizar">
      <div class="product__thumb fix p-relative">
         <a href="javascript:;" class="w-img">
            <img :src="arquivo.banner == null ? '' : arquivo.banner" alt="produto" @error="imageError">
         </a>
      </div>
      <div class="product__content text-center">
         <h6 class="product-name mt-1">
            <a href="javascript:;" class="product-item-link">
               <i class="fal fa-download font-14 me-1" :class="view == 'Produto-Videos' ? 'd-none' : ''" v-if="arquivo.urlDownload != null"></i> {{ arquivo.titulo }}
            </a>
         </h6>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Arquivo',
   props: ['arquivo', 'view'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      visualizar : function () {
         switch (this.view) {
            case 'Produto-Videos':
               this.$emit('visualizar', this.arquivo)
            break;
            default:
               this.download()
            break;
         }
      },
      download : function () {
         if (this.arquivo.urlDownload != null) {
            this.$emit('download', this.arquivo)
         }
      }
   }
}

</script>

<style scoped>

.product__item {
   border: 1px solid rgba(0,0,0,.125);
   border-radius: .25rem;
}

img {
	aspect-ratio: 1/1;
	object-fit: contain;
}

.product__item h6 {
   font-size: 16px;
   font-weight: 500;
}

.product__item h6 i {
   display: none;
}

.product__item:hover h6 i {
   display: inline;
}

.card {
   margin-bottom: 4px;
   color: var(--color-theme);
   border: 1px solid #d6dce1;
   border-radius: 4px;
   font-weight: 500;
}

.card .card-body {
   padding: 8px 12px;
}

.card .w-img img {
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

</style>